import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, notification } from 'antd';
import { passwordStrength } from 'check-password-strength';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../images/logo.png';
import { loginUser } from '../actions';
import { useAuthState } from '../iam-client';
import { LoginUserDto } from '../user-models';
import './login-component.css';


export const LoginComponent = () => {
    const { authContext, dispatch } = useAuthState();
    const navigate = useNavigate();
    const location: any = useLocation();

    const handleLogin = async (values: any) => {
        try {

            console.log(passwordStrength(values.password).value);
            const req = new LoginUserDto(values.username, values.password, authContext.authServerUrl,authContext.mapLoginPlantCode)
            let response = await loginUser(dispatch, req ,authContext);
            if (!response.user) return false;
            const from = location.state?.from;
            if (from) {
                navigate(from, { replace: true });
            } else {
                navigate("/", { replace: true });
            }
            return true;
        } catch (error: any) {
            notification.config({ maxCount: 3, duration: 3, placement: 'top' });
            notification.destroy();
            notification.error(
                {
                    message: 'Error',
                    description: error.message,
                }
            );
            return false;
        }
    };

    return (
        <Card
            style={{ width: '300px', padding: '0.75rem 0.75rem' }}>
            <div className='imgcontainer'>

                <div style={{ marginLeft: '20%' }} >
                    <img src={logo} width={150} height={'50%'} alt='PT-SDAM'></img>
                </div>
                {/* <h2>BATU KUMALA MULIA</h2> */}
            </div>
            <br />
            <Form
                name="login-form"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
                style={{ minWidth: 300 }}
            >
                <Form.Item style={{ width: '250px' }}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input width={215} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" autoComplete="off" />
                </Form.Item>

                <Form.Item style={{ width: '250px' }}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password width={215} prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>

                {/* <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <a style={{ float: 'right' }} href="/">
          Forgot password
        </a>
      </Form.Item> */}

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '250px' }}>
                        Log In
                    </Button>
                    {/* Or <a href="/">register now!</a> */}
                </Form.Item>
            </Form>
        </Card>
    )
}

export default LoginComponent;